/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')
require("@rails/ujs").start();
// require("@rails/activestorage").start()
// require("channels")
require("jquery");
require("popper.js");
// require("admin-lte")
require("bootstrap");
require("metismenu");
require("./jquery.nice-select.min.js");
require("./jquery.barfiller");
require("./jquery.dataTables.min.js");
require("slick-carousel");
// require("./login_slide")
require("./user");
require("./custom");
require("./entry");
require("./video");
import "cocoon";
import 'jquery-ui-dist/jquery-ui';
import Alert from '@bjnstnkvc/alert'
import '@bjnstnkvc/alert/src/alert.css'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.flash-message').forEach(function(element) {
    const type = element.dataset.type;
    const message = element.dataset.message;
    new Alert({
      type: type,
      message: message,
      container: '.alerts-container',
      duration: 30,
      expires: true,
      info: true
    });
  });
});