import $ from 'jquery';

$(function(){
  function changeDayForm() {
    var year  = $(".js-users-birth-year").val();
    var month = $(".js-users-birth-month").val();
    var day_e = $(".js-users-birth-day");
    var day   = day_e.val();

    if ( year.length > 0 && month.length > 0 ) {
      var ld = new Date(year , month , 0).getDate();
      var i  = 0;
      var options_html = '';
      for ( i = 1 ; i <= ld ; i ++ ) {
        options_html += '<option value="' + i + '">' + i + "</option>";
      }
      if ( day_e.length > 0 && ld >= day ) {
        day_e.html(options_html).val(day).change();
      } else {
        day_e.html(options_html).val("").change();
      }
    }
  }

  $(".js-users-birth-year, .js-users-birth-month").on("change" , function () {
    changeDayForm();
  });
})