document.addEventListener('DOMContentLoaded', function() {
  var video = document.getElementById('video');
  var playPauseButton = document.getElementById('playPause');
  var seekbar = document.getElementById('seekbar');
  var currentTimeDisplay = document.getElementById('currentTime');
  var durationDisplay = document.getElementById('duration');
  var volumeControl = document.getElementById('volumeControl'); // ボリュームコントロールの要素を取得
  var updateCalled = false; // update()funcが呼ばれたか確認するフラグ
  var videoSource = "/movie.mp4?=" + new Date().getTime();
  video.setAttribute('src', videoSource);

  video.addEventListener('loadedmetadata', function() {
    seekbar.max = video.duration;
    durationDisplay.textContent = formatTime(video.duration);
  });

  video.addEventListener('timeupdate', function() {
    seekbar.value = video.currentTime;
    currentTimeDisplay.textContent = formatTime(video.currentTime);
    // 動画が35分30秒以上試聴されたら、update()関数を呼び出す&モーダルウィンドウを表示する
    if (video.currentTime >= 2130 && !updateCalled) {
      $("#videoModal").modal('show');
      update();
      updateCalled = true;
    }
  });

  // 動画の要素自体で再生・停止ができるようにする
  video.addEventListener('click', function() {
    if (video.paused || video.ended) {
      video.play();
      updateButtonToPause();
    } else {
      video.pause();
      updateButtonToPlay();
    }
  });

  volumeControl.addEventListener('input', function() {
    video.volume = volumeControl.value;
  });

  playPauseButton.addEventListener('click', function() {
    if (video.paused) {
      video.play();
      updateButtonToPause();
    } else {
      video.pause();
      updateButtonToPlay();
    }
  });

  seekbar.addEventListener('change', function() {
    video.currentTime = seekbar.value;
  });

  seekbar.addEventListener('mousedown', function(event) {
    event.preventDefault(); // ユーザーによるシーク操作を無効化（マウス操作無効）
  });

  seekbar.addEventListener('touchstart', function(event) {
    event.preventDefault(); // ユーザーによるシーク操作を無効化（タッチ操作無効）
  });

  function formatTime(seconds) {
    var minutes = Math.floor(seconds / 60);
    var seconds = Math.floor(seconds % 60);
    return minutes + ":" + (seconds < 10 ? '0' + seconds : seconds);
  }

  function updateButtonToPlay() {
    playPauseButton.innerHTML = '<i class="far fa-play-circle"></i> 再生';
  }

  function updateButtonToPause() {
    playPauseButton.innerHTML = '<i class="far fa-stop-circle"></i> 一時停止';
  }

  // 動画を31分以上視聴したらDBを更新し、視聴日時を返す
  function update(){
    console.log("update")
    var path = window.location.href
    console.log(path)
    var parts = path.split('/');
    var token = parts[parts.length - 1];
    console.log(token)
    $.ajax({ 
      url: '/entry_assistants/update_attended_at',
      type: 'POST',
      data: {
        training_token: token,
      },
      dataType: 'json',
      success: function(data) {
        if (data.success) {
          console.log('視聴時間を更新しました。', data.attended_at);
          
          document.getElementById('completionTime').innerHTML = "視聴完了<br>ご視聴ありがとうございました";
        }
      },
      error: function() {
        console.error('視聴時間の更新に失敗しました。');
      }
    })
  }

  // 日付のフォーマット変換
  function formatDate(dateString) {
    var date = new Date(dateString);
  
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
  
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }
});