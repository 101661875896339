import $ from "jquery";

// 研究責任者名取得
$(function () {
  $("#user_organization_id").on("change", function () {
    // 選択された組織IDを取得
    var selectedOrganizationId = $(this).val();

    // Ajaxリクエストを送信して組織に関連付けられた研究責任者を取得
    $.ajax({
      url: "/get_director",
      type: "GET",
      data: { organization_id: selectedOrganizationId },
      dataType: "json",
      success: function (response) {
        // 取得した研究責任者をフォームにセット
        $("#user_director").val(response.director);
      },
      error: function () {},
    });
  });
});

// 郵便番号から住所を検索
$(function () {
  $("#zip_search").on("click", function () {
    $.ajax({
      url:
        "https://zipcloud.ibsnet.co.jp/api/search?zipcode=" +
        $("#zipcode").val(),
      dataType: "jsonp",
    })
      .done((data) => {
        if (data.results) {
          getData(data.results[0]);
        } else {
          alert("該当データが見つかりません");
        }
      })
      .fail((data) => {
        alert("通信に失敗しました");
      });
  });

  function getData(data) {
    $("#pref").val(data.address1);
    $("#city").val(data.address2);
    $("#town").val(data.address3);
  }
});

// 職種：その他選択された場合に、フリーテキストを表示する
$(function () {
  $(document).on('change', '.job-category-select', function () {
    var $this = $(this);
    var $otherJobCategoryField = $this.closest('.form-group').next('.other-job-category').find('input');

    if ($this.val() === 'other') {
      $otherJobCategoryField.closest('.other-job-category').removeClass('d-none');
    } else {
      $otherJobCategoryField.val('').closest('.other-job-category').addClass('d-none');
    }
  });

  $('.job-category-select').each(function () {
    var $this = $(this);
    var $otherJobCategoryField = $this.closest('.form-group').next('.other-job-category').find('input');

    if ($this.val() === 'other') {
      $otherJobCategoryField.closest('.other-job-category').removeClass('d-none');
    } else {
      $otherJobCategoryField.val('').closest('.other-job-category').addClass('d-none');
    }
  });
});

// ユーザが選択した内容によって、フォームを制御する（許可取得日・報告日）
document.addEventListener("DOMContentLoaded", () => {
  var sampleStatusSelect = document.querySelector(".existing-sample-status");
  var permittedAtField = document.getElementById("permitted_at");
  var newStatusSelect = document.querySelector(".new-sample-status");
  var reportedAtField = document.getElementById("reported_at");
  var badgePermittedAt = document.querySelector('.badge-permitted-at');
  var badgeReportedAt = document.querySelector('.badge-reported-at');

  togglePermittedAtField();
  sampleStatusSelect.addEventListener("change", togglePermittedAtField);

  function togglePermittedAtField() {
    if (sampleStatusSelect.value === "done") {
      permittedAtField.disabled = false;
      badgePermittedAt.style.display = '';
    } else {
      permittedAtField.disabled = true;
      permittedAtField.value = "";
      badgePermittedAt.style.display = 'none';
    }
  }

  toggleReportedAtField();
  newStatusSelect.addEventListener("change", toggleReportedAtField);

  function toggleReportedAtField() {
    if (newStatusSelect.value === "done") {
      reportedAtField.disabled = false;
      badgeReportedAt.style.display = '';
    } else {
      reportedAtField.disabled = true;
      reportedAtField.value = "";
      badgeReportedAt.style.display = 'none';
    }
  }
});

// ユーザが選択した内容によって、フォームを制御する（契約提携日）
document.addEventListener("DOMContentLoaded", () => {
  var contractSelect = document.querySelector('.signed-contract');
  var contractedAtField = document.getElementById('contracted_at');
  var badgeContractedAt = document.querySelector('.badge-contracted-at');

  toggleContractedAtField();
  contractSelect.addEventListener('change', toggleContractedAtField);

  function toggleContractedAtField() {
    if (contractSelect.value === 'done') {
      contractedAtField.disabled = false;
      badgeContractedAt.style.display = '';
    } else {
      contractedAtField.disabled = true;
      contractedAtField.value = '';
      badgeContractedAt.style.display = 'none';
    }
  }
});

// ユーザが選択した内容によって、フォームを制御する（教育研修受講有無）
$(function () {
  $(".attend-status-select").each(function () {
    var attendStatusSelect = $(this);
    var formPrefix = attendStatusSelect
      .attr("id")
      .replace(/_attend_status$/, "");
    var attendedAtField = $("#" + formPrefix + "_attended_at");

    toggleAttendedAtField(attendStatusSelect.val(), attendedAtField);
    attendStatusSelect.on("change", function () {
      toggleAttendedAtField(this.value, attendedAtField);
    });
  });

  function toggleAttendedAtField(value, attendedAtField) {
    var badge = attendedAtField.closest('.form-group').find('.badge-attend-at');
    if (value === "done") {
      attendedAtField.prop("disabled", false);
      badge.show();
    } else {
      attendedAtField.prop("disabled", true);
      attendedAtField.val("");
      badge.hide();
    }
  }
});

// IC取得補助者バリデーションチェック
$(function () {
  $("#form_submit").on("submit", function (e) {
    let hasError = false;
    let errorMessages = [];

    $('.assistant-errors').empty(); // エラーメッセージを空にする
    $(".assistant-group").each(function (index) {
      let group = $(this);
      let fieldsFilled =
        group.find(".assistant-name").val() ||
        group.find(".assistant-kana").val() ||
        group.find(".assistant-email").val() ||
        // group.find(".attend-status-select").val() ||
        group.find(".assistant-confidential-status").val();

      if (fieldsFilled) {
        if (!group.find(".assistant-name").val()) {
          errorMessages.push(
            "・IC取得補助者" + (index + 1) + "： 氏名（漢字）を入力してください"
          );
          hasError = true;
        }

        if (!group.find(".assistant-kana").val()) {
          errorMessages.push(
            "・IC取得補助者" + (index + 1) + "： 氏名（カナ）を入力してください"
          );
          hasError = true;
        }

        if (!group.find(".assistant-email").val()) {
          errorMessages.push(
            "・IC取得補助者" +
              (index + 1) +
              "： メールアドレスを入力してください"
          );
          hasError = true;
        }

        // if (!group.find(".attend-status-select").val()) {
        //   errorMessages.push(
        //     "・IC取得補助者" +
        //       (index + 1) +
        //       "： 教育研修受講有無を選択してください"
        //   );
        //   hasError = true;
        // }

        if (!group.find(".assistant-confidential-status").val()) {
          errorMessages.push(
            "・IC取得補助者" +
              (index + 1) +
              "： 法的守秘義務または守秘義務契約を選択してください"
          );
          hasError = true;
        }
      }
    });

    if (hasError) {
      e.preventDefault();

      let combinedMessages = errorMessages.join('<br>');
        new Alert({
          type: 'error',
          message: combinedMessages,
          container: '.assistant-errors', 
          expires: false
        });

      window.scrollTo(0, 0); // ページの最上部にスクロール

    } else {
      $('.assistant-errors').empty();
    }
  });
});

// IC取得補助者の動的フォーム（新規）
$(function () {
  let index = 3;
  $("#class-parent").on("cocoon:after-insert", function (e, insertedItem) {
    insertedItem.find(".attend-status-select").each(function () {
      var attendStatusSelect = $(this);
      var formPrefix = attendStatusSelect.attr("id").replace(/_attend_status$/, "");
      var attendedAtField = $("#" + formPrefix + "_attended_at");

      toggleAttendedAtField(attendStatusSelect.val(), attendedAtField);
      attendStatusSelect.on("change", function () {
        toggleAttendedAtField(this.value, attendedAtField);
      });
    });

    function toggleAttendedAtField(value, attendedAtField) {
      if (value === "done") {
        attendedAtField.prop("disabled", false);
      } else {
        attendedAtField.prop("disabled", true);
        attendedAtField.val("");
      }
    }
  
    index = index + 1;
    $(insertedItem).find(".collaspe_1").attr("href", `#collapse${index}`);
    $(insertedItem)
      .find(".collaspe_2")
      .attr("aria-labelledby", `heading${index}`);
    $(insertedItem).find(".collaspe_2").attr("id", `collapse${index}`);
    $(insertedItem).find(".collaspe_3").attr("id", `heading${index}`);
    $(insertedItem).find(".collaspe_4").text(index);
    $(".nested-fields").show();
    $(insertedItem).find(".form_insert").attr("class", `form_insert_${index}`);
    let targetElement = $(`.form_insert_${index - 1}`);
    let add_extra_entry_form = $("#add_extra_entry_form");
    if (targetElement.length) {
      insertedItem.insertAfter(targetElement);
    } else {
      insertedItem.insertAfter(add_extra_entry_form);
    }
    if ($("#accordionEntryAssistants .nested-fields:visible").length == 20) {
      $("#form_add_btn").hide();
    } else {
      $("#form_add_btn").show();
    }
  });
});

$(function () {
  $("#class-parent").on("cocoon:after-remove", function (e, insertedItem) {
    if ($("#accordionEntryAssistants .nested-fields:visible").length == 20) {
      $("#form_add_btn").hide();
    } else {
      $("#form_add_btn").show();
    }
  });
});

// IC取得補助者の動的フォーム（編集）
$(function () {
  let index = 0;
  let existing_assistants_count = $("#existing_assistants_count").val();
  index = Number(existing_assistants_count);
  $("#class-parent_edit").on("cocoon:after-insert", function (e, insertedItem) {
    insertedItem.find(".attend-status-select").each(function () {
      var attendStatusSelect = $(this);
      var formPrefix = attendStatusSelect.attr("id").replace(/_attend_status$/, "");
      var attendedAtField = $("#" + formPrefix + "_attended_at");

      toggleAttendedAtField(attendStatusSelect.val(), attendedAtField);
      attendStatusSelect.on("change", function () {
        toggleAttendedAtField(this.value, attendedAtField);
      });
    });

    function toggleAttendedAtField(value, attendedAtField) {
      if (value === "done") {
        attendedAtField.prop("disabled", false);
      } else {
        attendedAtField.prop("disabled", true);
        attendedAtField.val("");
      }
    }

    index = index + 1;
    $(insertedItem).find(".collaspe_1").attr("href", `#collapse${index}`);
    $(insertedItem)
      .find(".collaspe_2")
      .attr("aria-labelledby", `heading${index}`);
    $(insertedItem).find(".collaspe_2").attr("id", `collapse${index}`);
    $(insertedItem).find(".collaspe_3").attr("id", `heading${index}`);
    $(insertedItem).find(".collaspe_4").text(index);
    $(".nested-fields").show();
    $(insertedItem).find(".form_insert").attr("class", `form_insert_${index}`);
    let targetElement = $(`.form_insert_${index - 1}`);
    let add_extra_entry_form = $("#add_extra_entry_form");
    if (targetElement.length) {
      insertedItem.insertAfter(targetElement);
    } else {
      insertedItem.insertAfter(add_extra_entry_form);
    }
    if ($("#accordionEntryAssistants .nested-fields:visible").length == 20) {
      $("#form_add_btn").hide();
    } else {
      $("#form_add_btn").show();
    }
  });
});

$(function () {
  $("#class-parent_edit").on("cocoon:after-remove", function (e, insertedItem) {
    if ($("#accordionEntryAssistants .nested-fields:visible").length == 20) {
      $("#form_add_btn").hide();
    } else {
      $("#form_add_btn").show();
    }
  });
});
